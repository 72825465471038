@tailwind base;
@tailwind components;
@tailwind utilities;


@import url(https://www.dafontfree.net/embed/YWJoYXlhLWxpYnJlLXNlbWlib2xkLXJlZ3VsYXImZGF0YS8yNDUvYS8xODA0NjMvQWJoYXlhTGlicmUtU2VtaUJvbGQudHRm);

@layer base {
    html {
        font-family: 'abhaya-libre-semibold';
    }
  }